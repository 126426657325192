@import "./app-variables";
@import url('https://fonts.googleapis.com/css?family=Catamaran|Raleway');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,800');

$grid-breakpoints: (
  // iPhone SE
  xxs: 0,
  // Extra small screen / phone
  xs: 321px,
  // Small screen / phone
  sm: 544px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px
);


body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
}

a {
    font-size: 20px;
    border-bottom: 1px solid black;
    text-decoration: none;
    color: black;

    &:visited, &:hover, &:active {
        border-bottom: 1px solid black;
    }
}

button {
    font-size: 20px;

    &:focus {
        outline: 0;
    }
}

h1 {
    margin-top: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

h2 {
    margin-top: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-align: center;
}

p {
    font-weight: 200;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 2rem;
    line-height: 25px;
}

li {
    font-size: 20px;
}

.app {
    background-color: $silver-chalice;
    color: $cod-gray;
    padding: 1.5rem 10rem;

    @media (max-width: 1000px) {
        padding: 1.5rem 0;
    }
}

.btn {
    border: 1px solid $purple-dark;
    border-radius: 3px;
    color: white;
    display: block;
    text-align: center;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    padding: 10px;
    background: $purple-dark;
    transition: 0.2s ease;
    box-sizing: border-box;

    &:hover {
        cursor: pointer;
        background: $color-purple;
        text-decoration: none;
    }

    &__block {
        width: 100%;
    }

    &--link {
        border: 1px solid $color-yellow;
        border-radius: 3px;
        background: $color-yellow;

        color: white;
        font-weight: 400;
        text-decoration: none;

        &:hover {
            background: $yellow-light;
            text-decoration: none;
        }

        &, &:visited, &:hover, &:active {
            border-bottom: none;
        }
    }
}

.cards-wrapper {
    display: grid;
    justify-content: space-between;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
}

.card {
    padding: 1rem;
    border-radius: 0.3rem;
    border: 1px solid $gray;
    box-shadow: 1px 3px $silver-chalice;
    transition: 0.2s ease;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &--description {
        font-size: 14px;
        margin-bottom: 1rem;
    }

    &--image {
        width: 165px;
        margin-bottom: 1rem;

        @media(max-width: map-get($grid-breakpoints, md)) {
            width: 40px;
        }

        &__big {
            width: 215px;

            @media(max-width: map-get($grid-breakpoints, md)) {
                width: 60px;
            }
        }

        &__small {
            width: 125px;

            @media(max-width: map-get($grid-breakpoints, md)) {
                width: 30px;
            }
        }
    }

    &--image-wrapper {
        height: 250px;
        display: flex;
        align-items: center;

        @media(max-width: map-get($grid-breakpoints, md)) {
            height: 80px;
        }
    }

    &:hover {
        cursor: pointer;
        transform: translateY(-3px);
        box-shadow: 3px 5px $silver-chalice;
    }

    &__active {
        transform: translateY(-3px);

        &--blue {  border: 3px solid $color-blue; }
        &--green { border: 3px solid $color-green; }
    }
}

.cover {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 150px;
    border-top: 1px solid $cod-gray;
    border-bottom: 1px solid $cod-gray;

    &--tile {
        width: 3.03%;
        height: 20%;

         @media(max-width: map-get($grid-breakpoints, md)) {
            width: 4%;
        }

        @media(max-width: map-get($grid-breakpoints, sm)) {
            width: 8.28%;
        }

        &:hover {
            transform: scale(1.3);
            animation-name: buzz;
		    animation-duration: 1s;
		    animation-timing-function: linear;
		    animation-iteration-count: infinite;
        }
    }
}

.display-flex { display: flex; }

@keyframes buzz {
    50% {
        transform: translateX(3px) rotate(2deg);
    }

    100% {
        transform: translateX(-3px) rotate(-2deg);
    }
}

.section {
    background-color: white;
    color: $cod-gray;
    padding: 3rem;
    margin-bottom: 1rem;

    @media(max-width: map-get($grid-breakpoints, md)) {
        padding: 2rem;
    }

    &--images {
        margin-right: 1rem;

        @media(max-width: map-get($grid-breakpoints, md)) {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 0;
        }
    }

    &--image {
        height: 200px;
        margin: 0 0.25rem 1rem 0.25rem;

        @media(max-width: map-get($grid-breakpoints, md)) {
            height: auto;
            width: 100%;
        }
    }

    &--image-wrapper {
        text-align: center;

         @media(max-width: map-get($grid-breakpoints, md)) {
            padding: 0 1rem;
        }
    }

    &--content {
        display: flex;

        @media(max-width: map-get($grid-breakpoints, md)) {
            display: block;
        }
    }

    &--description {
        margin-left: 1rem;

        @media(max-width: map-get($grid-breakpoints, md)) {
            margin-top: 2rem;
            margin-left: 0;
        }
    }
}

.list {
    line-height: 32px;
    padding-left: 0;

    li { list-style-type: none; }
}

@mixin active-state($color, $light-color) {
    &:hover {
        box-shadow: inset 0px -5px $light-color;
        font-weight: 600;
    }

    &__active {
        color: $cod-gray;
        font-weight: 600;
        box-shadow: inset 0px -5px $color;

        &:hover {
            box-shadow: inset 0px -5px $color;
        }
    }
}

.nav {
    display: grid;
    grid-gap: 0;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);

    border-top: 1px solid $cod-gray;
    border-bottom: 1px solid $cod-gray;
    list-style: none;
    margin-bottom: 1.5rem;
    background-color: white;
    padding: 15px 40px;
    position: relative;

    @media(max-width: map-get($grid-breakpoints, sm)) {
        align-items: center;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 2rem;
        padding-left: 100px;
    }

    &--list {
        text-align: center;
    }

    &--logo {
        position: absolute;
        width: 48px;
        padding: 0.5rem 1rem;
    }

    &--item {
        color: $tundora;
        border: none;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        margin: 0 30px;
        padding-bottom: 15px;
        text-decoration: none;

        &:hover {
            cursor: pointer;
            text-decoration: none;
        }

        &, &:active, &:visited, &:hover {
            border: none;
        }

        &__1 { @include active-state($color-purple, $purple-light); }
        &__2 { @include active-state($color-green, $green-light); }
        &__3 { @include active-state($color-blue, $blue-light); }
        &__4 { @include active-state($color-yellow, $yellow-light); }
    }
}

.project {
    margin-top: 3rem;
    text-align: left;

    &--header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 2rem;
        border-bottom: 1px solid $silver-chalice;
    }

    &--title {
        text-align: left;
        margin-bottom: 1rem;
    }

    &--images {
        justify-content: space-between;
        margin-bottom: 1rem;
    }

    &--image {
        height: 250px;
        width: 100%;
        object-fit: contain;

        &__vertical {
            height: 530px;
        }
    }
}

.tag-wrapper {
    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase;
    font-size: 12px;
    width: 100%;
}

.tag {
    border-radius: 5px;
    color: white;
    font-weight: 400;
    padding: 0.1rem 0.4rem;
    margin-bottom: 0.25rem;
    margin-left: 0.25rem;

    &__green {
        border: 1px solid $color-green;
        background-color: $color-green;
    }

    &__blue {
        border: 1px solid $color-blue;
        background-color: $color-blue;
    }
}


///// GRID

@mixin grid-layout() {
    $cols: 8;

    @each $bp in map-keys($grid-breakpoints) {
        @for $col from 1 through $cols {
            &-#{$bp}-#{$col} {
                display: grid;
                grid-gap: 1rem;
                justify-content: space-between;

                @media(min-width: map-get($grid-breakpoints, $bp)) {
                    grid-template-columns: repeat($col, 1fr);
                }
            }
        }
    }
}

.grid {
    @include grid-layout();
}
