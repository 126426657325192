@import url(https://fonts.googleapis.com/css?family=Catamaran|Raleway);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600,800);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300; }

a {
  font-size: 20px;
  border-bottom: 1px solid black;
  text-decoration: none;
  color: black; }
  a:visited, a:hover, a:active {
    border-bottom: 1px solid black; }

button {
  font-size: 20px; }
  button:focus {
    outline: 0; }

h1 {
  margin-top: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600; }

h2 {
  margin-top: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-align: center; }

p {
  font-weight: 200;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 2rem;
  line-height: 25px; }

li {
  font-size: 20px; }

.app {
  background-color: #d9d9d9;
  color: #111;
  padding: 1.5rem 10rem; }
  @media (max-width: 1000px) {
    .app {
      padding: 1.5rem 0; } }

.btn {
  border: 1px solid #6A4994;
  border-radius: 3px;
  color: white;
  display: block;
  text-align: center;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  padding: 10px;
  background: #6A4994;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  box-sizing: border-box; }
  .btn:hover {
    cursor: pointer;
    background: #9970CE;
    text-decoration: none; }
  .btn__block {
    width: 100%; }
  .btn--link {
    border: 1px solid #EACB5D;
    border-radius: 3px;
    background: #EACB5D;
    color: white;
    font-weight: 400;
    text-decoration: none; }
    .btn--link:hover {
      background: #EAD99C;
      text-decoration: none; }
    .btn--link, .btn--link:visited, .btn--link:hover, .btn--link:active {
      border-bottom: none; }

.cards-wrapper {
  display: grid;
  justify-content: space-between;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr; }

.card {
  padding: 1rem;
  border-radius: 0.3rem;
  border: 1px solid #a6a6a6;
  box-shadow: 1px 3px #d9d9d9;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; }
  .card--description {
    font-size: 14px;
    margin-bottom: 1rem; }
  .card--image {
    width: 165px;
    margin-bottom: 1rem; }
    @media (max-width: 768px) {
      .card--image {
        width: 40px; } }
    .card--image__big {
      width: 215px; }
      @media (max-width: 768px) {
        .card--image__big {
          width: 60px; } }
    .card--image__small {
      width: 125px; }
      @media (max-width: 768px) {
        .card--image__small {
          width: 30px; } }
  .card--image-wrapper {
    height: 250px;
    display: flex;
    align-items: center; }
    @media (max-width: 768px) {
      .card--image-wrapper {
        height: 80px; } }
  .card:hover {
    cursor: pointer;
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
    box-shadow: 3px 5px #d9d9d9; }
  .card__active {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px); }
    .card__active--blue {
      border: 3px solid #61ADC5; }
    .card__active--green {
      border: 3px solid #3FB296; }

.cover {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 150px;
  border-top: 1px solid #111;
  border-bottom: 1px solid #111; }
  .cover--tile {
    width: 3.03%;
    height: 20%; }
    @media (max-width: 768px) {
      .cover--tile {
        width: 4%; } }
    @media (max-width: 544px) {
      .cover--tile {
        width: 8.28%; } }
    .cover--tile:hover {
      -webkit-transform: scale(1.3);
              transform: scale(1.3);
      -webkit-animation-name: buzz;
              animation-name: buzz;
      -webkit-animation-duration: 1s;
              animation-duration: 1s;
      -webkit-animation-timing-function: linear;
              animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite; }

.display-flex {
  display: flex; }

@-webkit-keyframes buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
            transform: translateX(3px) rotate(2deg); }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
            transform: translateX(-3px) rotate(-2deg); } }

@keyframes buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
            transform: translateX(3px) rotate(2deg); }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
            transform: translateX(-3px) rotate(-2deg); } }

.section {
  background-color: white;
  color: #111;
  padding: 3rem;
  margin-bottom: 1rem; }
  @media (max-width: 768px) {
    .section {
      padding: 2rem; } }
  .section--images {
    margin-right: 1rem; }
    @media (max-width: 768px) {
      .section--images {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0; } }
  .section--image {
    height: 200px;
    margin: 0 0.25rem 1rem 0.25rem; }
    @media (max-width: 768px) {
      .section--image {
        height: auto;
        width: 100%; } }
  .section--image-wrapper {
    text-align: center; }
    @media (max-width: 768px) {
      .section--image-wrapper {
        padding: 0 1rem; } }
  .section--content {
    display: flex; }
    @media (max-width: 768px) {
      .section--content {
        display: block; } }
  .section--description {
    margin-left: 1rem; }
    @media (max-width: 768px) {
      .section--description {
        margin-top: 2rem;
        margin-left: 0; } }

.list {
  line-height: 32px;
  padding-left: 0; }
  .list li {
    list-style-type: none; }

.nav {
  display: grid;
  grid-gap: 0;
  justify-content: center;
  grid-template-columns: repeat(4, 1fr);
  border-top: 1px solid #111;
  border-bottom: 1px solid #111;
  list-style: none;
  margin-bottom: 1.5rem;
  background-color: white;
  padding: 15px 40px;
  position: relative; }
  @media (max-width: 544px) {
    .nav {
      align-items: center;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 2rem;
      padding-left: 100px; } }
  .nav--list {
    text-align: center; }
  .nav--logo {
    position: absolute;
    width: 48px;
    padding: 0.5rem 1rem; }
  .nav--item {
    color: #444;
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    margin: 0 30px;
    padding-bottom: 15px;
    text-decoration: none; }
    .nav--item:hover {
      cursor: pointer;
      text-decoration: none; }
    .nav--item, .nav--item:active, .nav--item:visited, .nav--item:hover {
      border: none; }
    .nav--item__1:hover {
      box-shadow: inset 0px -5px #CBB3EA;
      font-weight: 600; }
    .nav--item__1__active {
      color: #111;
      font-weight: 600;
      box-shadow: inset 0px -5px #9970CE; }
      .nav--item__1__active:hover {
        box-shadow: inset 0px -5px #9970CE; }
    .nav--item__2:hover {
      box-shadow: inset 0px -5px #ABDFD2;
      font-weight: 600; }
    .nav--item__2__active {
      color: #111;
      font-weight: 600;
      box-shadow: inset 0px -5px #3FB296; }
      .nav--item__2__active:hover {
        box-shadow: inset 0px -5px #3FB296; }
    .nav--item__3:hover {
      box-shadow: inset 0px -5px #ADD9E7;
      font-weight: 600; }
    .nav--item__3__active {
      color: #111;
      font-weight: 600;
      box-shadow: inset 0px -5px #61ADC5; }
      .nav--item__3__active:hover {
        box-shadow: inset 0px -5px #61ADC5; }
    .nav--item__4:hover {
      box-shadow: inset 0px -5px #EAD99C;
      font-weight: 600; }
    .nav--item__4__active {
      color: #111;
      font-weight: 600;
      box-shadow: inset 0px -5px #EACB5D; }
      .nav--item__4__active:hover {
        box-shadow: inset 0px -5px #EACB5D; }

.project {
  margin-top: 3rem;
  text-align: left; }
  .project--header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 2rem;
    border-bottom: 1px solid #d9d9d9; }
  .project--title {
    text-align: left;
    margin-bottom: 1rem; }
  .project--images {
    justify-content: space-between;
    margin-bottom: 1rem; }
  .project--image {
    height: 250px;
    width: 100%;
    object-fit: contain; }
    .project--image__vertical {
      height: 530px; }

.tag-wrapper {
  display: flex;
  flex-wrap: wrap;
  text-transform: uppercase;
  font-size: 12px;
  width: 100%; }

.tag {
  border-radius: 5px;
  color: white;
  font-weight: 400;
  padding: 0.1rem 0.4rem;
  margin-bottom: 0.25rem;
  margin-left: 0.25rem; }
  .tag__green {
    border: 1px solid #3FB296;
    background-color: #3FB296; }
  .tag__blue {
    border: 1px solid #61ADC5;
    background-color: #61ADC5; }

.grid-xxs-1 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 0) {
    .grid-xxs-1 {
      grid-template-columns: repeat(1, 1fr); } }

.grid-xxs-2 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 0) {
    .grid-xxs-2 {
      grid-template-columns: repeat(2, 1fr); } }

.grid-xxs-3 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 0) {
    .grid-xxs-3 {
      grid-template-columns: repeat(3, 1fr); } }

.grid-xxs-4 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 0) {
    .grid-xxs-4 {
      grid-template-columns: repeat(4, 1fr); } }

.grid-xxs-5 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 0) {
    .grid-xxs-5 {
      grid-template-columns: repeat(5, 1fr); } }

.grid-xxs-6 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 0) {
    .grid-xxs-6 {
      grid-template-columns: repeat(6, 1fr); } }

.grid-xxs-7 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 0) {
    .grid-xxs-7 {
      grid-template-columns: repeat(7, 1fr); } }

.grid-xxs-8 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 0) {
    .grid-xxs-8 {
      grid-template-columns: repeat(8, 1fr); } }

.grid-xs-1 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 321px) {
    .grid-xs-1 {
      grid-template-columns: repeat(1, 1fr); } }

.grid-xs-2 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 321px) {
    .grid-xs-2 {
      grid-template-columns: repeat(2, 1fr); } }

.grid-xs-3 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 321px) {
    .grid-xs-3 {
      grid-template-columns: repeat(3, 1fr); } }

.grid-xs-4 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 321px) {
    .grid-xs-4 {
      grid-template-columns: repeat(4, 1fr); } }

.grid-xs-5 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 321px) {
    .grid-xs-5 {
      grid-template-columns: repeat(5, 1fr); } }

.grid-xs-6 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 321px) {
    .grid-xs-6 {
      grid-template-columns: repeat(6, 1fr); } }

.grid-xs-7 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 321px) {
    .grid-xs-7 {
      grid-template-columns: repeat(7, 1fr); } }

.grid-xs-8 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 321px) {
    .grid-xs-8 {
      grid-template-columns: repeat(8, 1fr); } }

.grid-sm-1 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 544px) {
    .grid-sm-1 {
      grid-template-columns: repeat(1, 1fr); } }

.grid-sm-2 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 544px) {
    .grid-sm-2 {
      grid-template-columns: repeat(2, 1fr); } }

.grid-sm-3 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 544px) {
    .grid-sm-3 {
      grid-template-columns: repeat(3, 1fr); } }

.grid-sm-4 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 544px) {
    .grid-sm-4 {
      grid-template-columns: repeat(4, 1fr); } }

.grid-sm-5 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 544px) {
    .grid-sm-5 {
      grid-template-columns: repeat(5, 1fr); } }

.grid-sm-6 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 544px) {
    .grid-sm-6 {
      grid-template-columns: repeat(6, 1fr); } }

.grid-sm-7 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 544px) {
    .grid-sm-7 {
      grid-template-columns: repeat(7, 1fr); } }

.grid-sm-8 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 544px) {
    .grid-sm-8 {
      grid-template-columns: repeat(8, 1fr); } }

.grid-md-1 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 768px) {
    .grid-md-1 {
      grid-template-columns: repeat(1, 1fr); } }

.grid-md-2 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 768px) {
    .grid-md-2 {
      grid-template-columns: repeat(2, 1fr); } }

.grid-md-3 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 768px) {
    .grid-md-3 {
      grid-template-columns: repeat(3, 1fr); } }

.grid-md-4 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 768px) {
    .grid-md-4 {
      grid-template-columns: repeat(4, 1fr); } }

.grid-md-5 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 768px) {
    .grid-md-5 {
      grid-template-columns: repeat(5, 1fr); } }

.grid-md-6 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 768px) {
    .grid-md-6 {
      grid-template-columns: repeat(6, 1fr); } }

.grid-md-7 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 768px) {
    .grid-md-7 {
      grid-template-columns: repeat(7, 1fr); } }

.grid-md-8 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 768px) {
    .grid-md-8 {
      grid-template-columns: repeat(8, 1fr); } }

.grid-lg-1 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 992px) {
    .grid-lg-1 {
      grid-template-columns: repeat(1, 1fr); } }

.grid-lg-2 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 992px) {
    .grid-lg-2 {
      grid-template-columns: repeat(2, 1fr); } }

.grid-lg-3 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 992px) {
    .grid-lg-3 {
      grid-template-columns: repeat(3, 1fr); } }

.grid-lg-4 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 992px) {
    .grid-lg-4 {
      grid-template-columns: repeat(4, 1fr); } }

.grid-lg-5 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 992px) {
    .grid-lg-5 {
      grid-template-columns: repeat(5, 1fr); } }

.grid-lg-6 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 992px) {
    .grid-lg-6 {
      grid-template-columns: repeat(6, 1fr); } }

.grid-lg-7 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 992px) {
    .grid-lg-7 {
      grid-template-columns: repeat(7, 1fr); } }

.grid-lg-8 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 992px) {
    .grid-lg-8 {
      grid-template-columns: repeat(8, 1fr); } }

.grid-xl-1 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 1200px) {
    .grid-xl-1 {
      grid-template-columns: repeat(1, 1fr); } }

.grid-xl-2 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 1200px) {
    .grid-xl-2 {
      grid-template-columns: repeat(2, 1fr); } }

.grid-xl-3 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 1200px) {
    .grid-xl-3 {
      grid-template-columns: repeat(3, 1fr); } }

.grid-xl-4 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 1200px) {
    .grid-xl-4 {
      grid-template-columns: repeat(4, 1fr); } }

.grid-xl-5 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 1200px) {
    .grid-xl-5 {
      grid-template-columns: repeat(5, 1fr); } }

.grid-xl-6 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 1200px) {
    .grid-xl-6 {
      grid-template-columns: repeat(6, 1fr); } }

.grid-xl-7 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 1200px) {
    .grid-xl-7 {
      grid-template-columns: repeat(7, 1fr); } }

.grid-xl-8 {
  display: grid;
  grid-gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 1200px) {
    .grid-xl-8 {
      grid-template-columns: repeat(8, 1fr); } }

