// Colors

$lime: #b0eb00;
$bright-red: #b30015;
$dark-blue: #2a00b3;
$deep-cerulean: #0077b3;
$bondi-blue: #00b0b3;
$cyan: #24fbff;
$forest-green: #114511;
$heliotrope: #8a7dff;
$silver-chalice: #d9d9d9;
$gray: #a6a6a6;
$scorpion: #595959;
$tundora: #444;
$mine-shaft: #363636;
$cod-gray: #111;


// Color variables for tabs

// purple
$color-purple: #9970CE;
$purple-dark: #6A4994;
$purple-light: #CBB3EA;

// blue
$color-blue: #61ADC5;
$blue-dark: #3E798C;
$blue-light: #ADD9E7;

// green
$color-green: #3FB296;
$green-dark: #307161;
$green-light: #ABDFD2;

// yellow
$color-yellow: #EACB5D;
$yellow-dark: #E2B031;
$yellow-light: #EAD99C;

// tab 1
$tab-1-1: $color-purple;
$tab-1-2: $purple-dark;
$tab-1-3: $purple-light;
$tab-1-4: $blue-light;
$tab-1-5: $yellow-dark;

// tab 2
$tab-2-1: $color-blue;
$tab-2-2: $blue-dark;
$tab-2-3: $blue-light;
$tab-2-4: $green-dark;
$tab-2-5: $purple-light;

// tab 3
$tab-3-1: $color-green;
$tab-3-2: $green-dark;
$tab-3-3: $green-light;
$tab-3-4: $yellow-light;
$tab-3-5: $purple-dark;

// tab 4
$tab-4-1: $color-yellow;
$tab-4-2: $yellow-dark;
$tab-4-3: $yellow-light;
$tab-4-4: $blue-dark;
$tab-4-5: $green-light;
